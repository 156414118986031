import { Link } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import React from 'react';
import styled from 'styled-components';

import translate from '../constants/translations.json';
import { media } from '../styles/media';

const siteName = process.env.GATSBY_SITE_NAME;

const Section = styled.footer`
  position: relative;
  z-index: 0;
  .logo {
    height: 13px;
    width: auto;
    @media ${media.mdUp} {
      height: 15px;
    }
    @media ${media.lgUp} {
      height: 22px;
    }
  }
`;

export default function Footer({ data, langKey }) {
  const global = data.edges[0].node.data;
  return (
    <Section id="footer" className="bg--black">
      <div className="container pb-50 pt-80 lg:pt-160">
        <div className="grid grid-cols-4 pb-12 md:grid-cols-8 md:pb-16 lg:grid-cols-12 lg:pb-70">
          <div className="col-span-4 md:col-span-4 lg:col-span-3">
            <Link to={langKey === 'en-us' ? `/` : `/${langKey}/`}>
              <img
                src={global.logo_light.url}
                alt={siteName}
                className="logo db"
              />
            </Link>
          </div>
          <div className="col-span-2 col-start-1 mt-12 md:col-start-5 md:mt-0 lg:col-start-7">
            <div className="eyebrow color--grey-4 mb-30 md:mb-40">
              {global?.footer_company}
            </div>
            <ul>
              {global.footer_nav?.map((item, index) => (
                <li className="mb-3" key={index}>
                  <AniLink
                    cover
                    direction="up"
                    duration={1}
                    bg="#FAFAFA"
                    to={item.url}
                    className="btn--text btn--text--large color--white">
                    {item.title}
                  </AniLink>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-span-2 col-start-3 mt-12 md:col-start-7 md:mt-0 lg:col-start-10">
            <div className="eyebrow color--grey-4 mb-30 md:mb-40">
              {global?.footer_contact_us}
            </div>
            <ul>
              <li className="mb-3">
                <a
                  href={global?.instagram_link}
                  target="_blank"
                  rel="noreferrer"
                  className="btn--text btn--text--large color--white">
                  {global?.instagram}
                </a>
              </li>
              <li className="mb-3">
                <a
                  href={global?.facebook_link}
                  target="_blank"
                  rel="noreferrer"
                  className="btn--text btn--text--large color--white">
                  {global?.facebook}
                </a>
              </li>
              <li className="mb-3">
                <a
                  href={global?.sales_link}
                  rel="noreferrer"
                  className="btn--text btn--text--large color--white">
                  {global?.sales}
                </a>
              </li>
              {global?.phone && global?.phone_link && (
                <li className="mb-3">
                  <span className="btn--text btn--text--large color--white before:hidden ">
                    {global?.phone}
                  </span>{' '}
                  <a
                    href={`tel:${global?.phone_link?.replace(/-/g, '')}`}
                    rel="noreferrer"
                    className="btn--text btn--text--large color--white">
                    {global?.phone_link}
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
        <hr className="hr--grey" />
        <div className="pt-20 md:grid md:grid-cols-8 lg:grid-cols-12">
          <div className="md:col-span-4">
            <p className="xsmall color--white mb-0 text-center md:text-left">
              {siteName} &copy;{new Date().getFullYear()}.{' '}
              {translate[`${langKey}`].all_rights_reserved}.
            </p>
          </div>
          <div className="mt-20 md:col-span-2 md:col-start-7 md:mt-0 lg:col-span-3 lg:col-start-10">
            <ul className="flex justify-center md:justify-start">
              <li className="xsmall color--white">
                <AniLink
                  cover
                  direction="up"
                  duration={1}
                  bg="#FAFAFA"
                  to={
                    langKey === 'en-us'
                      ? `/privacy-policy/`
                      : `/${langKey}/privacy-policy/`
                  }>
                  {translate[`${langKey}`].privacy_policy}
                </AniLink>
              </li>
              <li className="xsmall color--white ml-45">
                <AniLink
                  cover
                  direction="up"
                  duration={1}
                  bg="#FAFAFA"
                  to={
                    langKey === 'en-us'
                      ? `/cookies-policy/`
                      : `/${langKey}/cookies-policy/`
                  }>
                  {translate[`${langKey}`].cookie_policy}
                </AniLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <a href="#" className="btn--circle btn--chat hidden">
        <svg
          width="21"
          height="22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0 1.506C0 .674.648 0 1.448 0h18.104C20.352 0 21 .674 21 1.506v20.117c0 .296-.313.476-.554.32l-6.176-4.817H1.448c-.8 0-1.448-.674-1.448-1.505V1.506z"
            fill="#44433F"
          />
        </svg>
      </a>
    </Section>
  );
}
